import React, { Component } from 'react';
import Loader from './components/MoonLoader';
import NcfxLogo from './assets/ncfx.svg';
import { Socket } from 'phoenix';
import logo from './logo.svg';
import './App.css';

class App extends Component {
  state = {
    connectedToRealtime: false,
    pairs: []
  }

  componentDidMount() {
    const socket = new Socket('wss://realtime-socket-dev.tokenhouse.dev/socket');

    socket.connect()
    this.socket = socket;

    const chan = socket.channel('ticker', {
      client: 'browser'
    });

    chan.join();

    chan.on("init_state", ({pairs}) => {
      const order = ["EURUSD","USDJPY","GBPUSD","AUDUSD","USDCAD","USDCHF","NZDUSD","USDCNH","EURGBP","EURCHF","EURJPY","EURCZK","EURDKK","EURPLN","EURRUB","EURTRY","USDMXN","USDNOK","USDPLN","USDRON","USDRUB","USDSEK","USDSGD","USDTRY","USDZAR","USDCZK","USDDKK","USDHKD","USDHUF","USDILS","EURAUD","EURCAD","EURHKD","EURHUF","EURMXN","EURNOK","EURNZD","EURSEK","EURSGD","EURZAR","GBPAUD","GBPCAD","GBPCHF","GBPJPY","GBPMXN","GBPNOK","GBPNZD","GBPPLN","GBPSEK","GBPSGD","GBPTRY","GBPZAR","AUDCAD","AUDCHF","AUDJPY","AUDNOK","AUDNZD","AUDSEK","AUDSGD","CADCHF","CADJPY","CHFJPY","NOKSEK","NZDCAD","NZDCHF","NZDJPY","NZDNOK","NZDSEK","NZDSGD"];
      let new_order = order.map(pair => {
        return {pair: pair, price: pairs.find(p => p.pair === pair)["price"]};
      });

      // console.log(ordered)
      this.setState({pairs: new_order, connectedToRealtime: true, ready: true});
    });

    chan.on("pair_update", ({pair, price}) => {
      let pairs = this.state.pairs;
      const prevIndex = pairs.findIndex(p => p.pair === pair);
      if(!pairs[prevIndex]) return;
      const prevPrice = pairs[prevIndex]["price"];

      pairs[prevIndex] = {pair, price};

      document.getElementById(pair).className = "priceContainer" + (price < prevPrice ? " down" : "");
      setTimeout(async() => {
        document.getElementById(pair).className = "priceContainer resting";
      }, 300)
      this.setState({pairs});
    });
  }

  render() {
    return (
      <div style={{width: "100vw", height: "100vh", display: "flex", alignItems: "center", flexDirection: "column"}}>
        <div className="toolbar">
          <div style={{width: "95%", display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center"}}>
            <img style={{height: 50, marginRight: 20}} src={NcfxLogo}/>
            <h2>NCFX Realtime Spot Benchmarks</h2>
          </div>
        </div>
        <div className="content">
          {this.state.connectedToRealtime ? (
            <div className="priceListContainer">
              {this.state.pairs.map(p => {
                return (
                  <div key={p.pair} className="priceContainer resting" id={p.pair}>
                    <h2 style={{margin: 0}}>{p.pair}</h2>
                    <h3 style={{fontFamily: "IBM Plex Mono", margin: 0}}>{p.price}</h3>
                  </div>
                )
              })}
            </div>
          ) : (
            <div style={{width: "100%", height: "100%", display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column"}}>
              <Loader color="#7e6fe8"/>
              <h3>Connecting to realtime...</h3>
            </div>
          )}
        
        </div>
      </div>
    );
  }
}

export default App;
